<template>
    <div class="page-content">
        <div class="d-flex align-center">
            <h2 class="page-title">{{$store.state.language.face.title}}</h2>
        </div>
        <div v-for="(item, i) in $store.state.language.face.document" :key="i" :class="(item.element === 'video' || item.element === 'img') ? 'd-flex align-center justify-center' : ''">
            <p v-if="item.element === 'p'" v-html="item.text" :class="item.class ? item.class : ''"></p>
            <h3 v-if="item.element === 'h3'" v-html="item.text" :class="item.class ? item.class : ''" style="font-size: 23px;"></h3>
            <div class="pl-4" v-if="item.element === 'ul'">
                <ul>
                    <li v-for="(child, index) in item.childs" :key="index">
                        <p v-if="child.element === 'p'" v-html="child.text" :class="child.class ? child.class : ''"></p>
                        <div v-if="child.element === 'div'" v-html="child.text" :class="child.class ? child.class : ''"></div>
                    </li>
                </ul>
            </div>
            <img v-if="item.element === 'img'" :src="require('@/assets/img/pages/' + item.src)" :class="item.class ? item.class : ''">
        </div>
    </div>
</template>
<script>
export default {
    name: 'Face'
}
</script>
<style scoped>
    .page-content{
        max-width: 1300px;
        margin: 100px auto;
        padding: 0px 25px;
    }
    .page-title{
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 25px;
        letter-spacing: 5px;
        text-shadow: -2px 0px 0px rgb(251 188 17 / 0%);
    }
    .page-content p{
        color: rgba(29,29,36,.75);
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 0;
    }
</style>